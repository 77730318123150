import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {OrderDetailsSection} from '../OrderDetailsSection/OrderDetailsSection';
import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../../settingsParams';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {getPlanFrequencyByDurationTranslationKey} from '../../../order.helper';

export enum OrderPriceDataHook {
  root = 'OrderPriceDataHook.root',
  priceLabel = 'OrderPriceDataHook.priceLabel',
  price = 'OrderPriceDataHook.price',
}

export function OrderPrice() {
  const {get} = useSettings();
  const {t} = useTranslation();
  const {formattedTotalPrice, isSubscription, subscriptionFrequency} = useControllerProps().thankYouPageStore;

  const getFormattedSubscriptionPrice = () => {
    const frequencyUnit = t(getPlanFrequencyByDurationTranslationKey(subscriptionFrequency));
    return t('THANK_YOU_PAGE_PLAN_FREQUENCY_DESCRIPTION', {
      price: formattedTotalPrice,
      frequencyUnitSingular: frequencyUnit,
    });
  };

  const getTotalTitle = () => {
    return get(settingsParams.THANK_YOU_PAGE_TOTAL_COST_LABEL) ?? t('thankYou.TOTAL_LABEL');
  };

  return (
    <OrderDetailsSection dataHook={OrderPriceDataHook.root}>
      <OrderDetailsSection.Title dataHook={OrderPriceDataHook.priceLabel}>{getTotalTitle()}</OrderDetailsSection.Title>
      <OrderDetailsSection.Content dataHook={OrderPriceDataHook.price}>
        {isSubscription ? getFormattedSubscriptionPrice() : formattedTotalPrice}
      </OrderDetailsSection.Content>
    </OrderDetailsSection>
  );
}
